html, body, #root, #App {
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: absolute;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

fieldset {
	border: 1px solid #bdbdbd;
}

span.coloredCircle {
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 5px;
	background-color: grey;
}

span.coloredCircleGreen {
	background-color: green;
}

span.coloredCircleRed {
	background-color: red;
}

.fadeIn {
	opacity:1;
	transition: opacity 0.5s;
}

.fadeOut {
	opacity:0;
	transition: opacity 0.5s;
}

#map.mouse-pointer .mapboxgl-canvas {
	cursor: pointer;
}

#map.mouse-add .mapboxgl-canvas {
	cursor: crosshair;
}

#map.mouse-move .mapboxgl-canvas {
	cursor: move;
}

